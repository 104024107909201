<app-loader [isActive]="isLoading.value$ | async" [isAfterViewInit]="false" [enableCircle]="true"></app-loader>
<h1 matDialogTitle>
  <div class="flex flex-row items-center justify-start gap-2">
    <div class="flex flex-col">
      <div>{{ 'time.update' | translate }}</div>
      <div *ngIf="isPastTime" class="text-warn text-sm">
        {{ 'comego.isPastTimeUpdate' | translate }}
      </div>
    </div>
    <div class="flex-auto"></div>
    <div class="flex items-center space-x-2">
      <button
        mat-stroked-button
        color="warn"
        *ngIf="deletePerm$ | async"
        (click)="delete(confirmDeleteDialog)"
        tabindex="-1"
        [disabled]="isLoading.value$ | async"
      >
        <span translate>utils.delete</span>
      </button>
      <button mat-icon-button matDialogClose tabindex="-1" type="button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</h1>
<mat-dialog-content cdkTrapFocus>
  <form
    (submit)="$event.preventDefault()"
    [formGroup]="this.group"
    class="mb-8 flex flex-col space-y-4"
    *ngIf="this.group as grp"
  >
    <div class="flex flex-row gap-2">
      <div class="flex flex-auto flex-col items-stretch justify-start gap-2 lg:wflex-[1_0_440px]">
        <div
          class="flex w-full cursor-pointer flex-col"
          (click)="$event.preventDefault(); openUserPicker()"
          *ngIf="workspace$canManageOthers.value$ | async"
        >
          <mat-form-field class="form-control-interactive">
            <mat-label>{{ 'user.select' | translate }}</mat-label>
            <div *ngIf="groupValue.user as user" class="-mb-16px flex items-center space-x-1.5">
              <app-user-avatar size="40px" class="rounded-full" [userId]="user.id"></app-user-avatar>
              <div class="flex flex-auto flex-col leading-none">
                <span>{{ user.name }}</span>
                <span class="mat-hint text-sm">{{ user.email }}</span>
              </div>
              <button
                *ngIf="postDiffUser$ | async"
                mat-icon-button
                type="button"
                (click)="$event.preventDefault(); $event.stopPropagation(); patchValue({ user: null })"
              >
                <mat-icon>clear</mat-icon>
              </button>
            </div>
            <input matInput [value]="groupValue.user" readonly class="!pointer-events-none" />
          </mat-form-field>
        </div>
        <div class="flex w-full cursor-pointer flex-col" (click)="$event.preventDefault(); openCalPicker()">
          <mat-form-field class="pointer-events-none">
            <mat-label>{{ 'timer.date' | translate }}</mat-label>
            <input
              matInput
              [value]="groupValue.date | formatDate"
              [disabled]="group.disabled || group.controls.date?.disabled"
              class="!pointer-events-none"
            />
          </mat-form-field>
        </div>
        <mat-form-field *ngIf="{ error: getErrorObservable('name') | async } as ctrl">
          <mat-label>{{ 'Description' | translate }}</mat-label>
          <textarea type="text" matInput formControlName="name" cdkInitialFocus cols="3"></textarea>
          <mat-error *ngIf="ctrl.error as error">{{
            error.content | translate: (error.args | translateArgs)
          }}</mat-error>
          <mat-hint class="w-full text-right"> {{ groupValue.name?.length ?? 0 }} / {{ maxLength }} </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="mat-bd-5 -mx-4 mb-8 flex flex-col space-y-4 rounded-lg px-4 pb-4 pt-3">
      <div class="mb-4 flex flex-col">
        <div class="flex flex-col">
          <ng-container *ngIf="duration$ | async as dur">
            <span class="text-muted mat-typography"
              >{{ 'comego.types.' + dur.type | translate }}:
              {{ dur.sum | parseMsAsDuration: false }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="flex flex-col space-y-3" *ngIf="{ range: (groupError$ | async)?.range } as err">
        @if (recording && (recording$ | async); as rec) {
          <div class="grid grid-cols-[1fr_100px] flex-auto gap-4">
            <div class="flex space-x-2">
              <div
                class="mt-4 flex size-6 items-center justify-center rounded-full bg-white p-1 leading-6 shadow dark:bg-neutral-600 dark:shadow-none flex-shrink-0"
                *ngIf="iconMap[groupValue.type] as type"
              >
                <mat-icon inline class="text-center">{{ type }}</mat-icon>
              </div>
              <mat-form-field class="wflex-[1_0_140px]">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type" disabled>
                  <mat-option value="work">{{ 'comego.types.work' | translate }}</mat-option>
                  <mat-option value="pause">{{ 'comego.types.pause' | translate }}</mat-option>
                  <mat-option value="absence">{{ 'comego.types.absence' | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex items-center space-x-2 justify-center">
              <div class="mat-size-1.5 bg-red-500 animate-pulse rounded-full"></div>
              <div>{{ rec.sum | parseMsAsDuration }}</div>
            </div>
          </div>
        } @else {
          <div class="flex flex-col">
            <div ngClass.gt-sm="group flex space-x-2 flex-grow" ngClass.lt-md="group flex flex-col space-y-1 space-x-0">
              <div class="flex flex-grow space-x-2">
                <div
                  class="mt-4 flex size-6 items-center justify-center rounded-full bg-white p-1 leading-6 shadow dark:bg-neutral-600 dark:shadow-none"
                  *ngIf="iconMap[groupValue.type] as type"
                >
                  <mat-icon inline class="text-center">{{ type }}</mat-icon>
                </div>
                <mat-form-field class="wflex-[1_0_140px]">
                  <mat-label>Type</mat-label>
                  <mat-select formControlName="type">
                    <mat-option value="work">{{ 'comego.types.work' | translate }}</mat-option>
                    <mat-option value="pause">{{ 'comego.types.pause' | translate }}</mat-option>
                    <mat-option value="absence">{{ 'comego.types.absence' | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div
                ngClass.gt-sm="grid grid-cols-[90px_1fr_90px] items-center space-x-2"
                ngClass.lt-md="grid grid-cols-[1fr_40px_1fr] items-center"
              >
                <app-time-input
                  [inputPlaceholder]="'timer.time.start' | translate"
                  matInput
                  formControlName="start"
                  [displayFormat]="false"
                  [maxlength]="5"
                  elementRef
                  #startInpRef="nativeElement"
                  (focus)="this.selectInput($event, startInpRef)"
                  (keydown.enter)="$event.preventDefault()"
                  (click)="$event.stopPropagation(); this.selectInput($event, startInpRef)"
                >
                  <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
                    <mat-form-field fxFill>
                      <mat-label>{{ inputPlaceholder }}</mat-label>
                      <input type="text" matInput [placeholder]="inputPlaceholder" [formControl]="control" />
                      <mat-error *ngIf="err.range"> &nbsp; </mat-error>
                    </mat-form-field>
                  </ng-template>
                </app-time-input>
                <span class="tg-seperator mx-2">
                  <mat-icon>arrow_forward</mat-icon>
                </span>
                <app-time-input
                  [inputPlaceholder]="'timer.time.end' | translate"
                  matInput
                  formControlName="end"
                  [displayFormat]="false"
                  [maxlength]="5"
                  elementRef
                  #endInpRef="nativeElement"
                  (focus)="this.selectInput($event, endInpRef)"
                  (keydown.enter)="$event.preventDefault()"
                  (click)="$event.stopPropagation(); this.selectInput($event, endInpRef)"
                >
                  <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
                    <mat-form-field fxFill>
                      <mat-label>{{ inputPlaceholder }}</mat-label>
                      <input type="text" matInput [placeholder]="inputPlaceholder" [formControl]="control" />
                      <mat-hint *ngIf="duration$ | async as duration">
                        {{ duration | parseMsAsDuration: false }}
                      </mat-hint>
                      <mat-error *ngIf="err.range"> &nbsp; </mat-error>
                    </mat-form-field>
                  </ng-template>
                </app-time-input>
              </div>
            </div>
            <div class="text-warn -mt-2 ml-8" *ngIf="err.range as error">
              {{ error.content | translate }}
            </div>
          </div>
        }
      </div>
    </div>
  </form>
  <ng-container
    *ngIf="{
      times: !recording && workingHoursError$ | async,
      manual: error$disabled | async,
    } as error"
  >
    <div class="pb-16" *ngIf="error.manual || error.times"></div>
    <div class="absolute inset-x-0 bottom-0 mx-3 mb-14">
      <div
        class="alert alert-tight rounded py-2"
        *ngIf="error.manual as err"
        [ngClass]="{
          'alert-danger': !err.type,
          'alert-info': err.type === 'info',
        }"
      >
        {{ err.message | translate: (err.args | translateArgs) }}
      </div>
    </div>
    <ng-container *ngIf="!error.manual && error.times as err">
      <div class="absolute inset-x-0 bottom-0 mx-3 mb-14">
        <div
          class="alert alert-tight rounded py-2"
          [ngClass]="{
            'alert-danger': !err.type,
            'alert-info': err.type === 'info',
          }"
        >
          {{ err.content | translate: (err.args || {} | translateArgs) }}
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="getErrorObservable('date') | async as error">
    <ng-container *ngIf="error as br">
      <div class="absolute inset-x-0 bottom-0 mx-3 mb-14">
        <div class="alert alert-danger alert-tight rounded py-2">
          {{ br.content | translate: (br.args || {} | translateArgs) }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="mat-action-filled">
  @if (recording) {
    <button
      mat-flat-button
      class="mat-action-filled"
      [color]="recording ? 'warn' : 'primary'"
      (click)="submit()"
      [disabled]="group.invalid || (error$.value$ | async)"
    >
      {{ 'comego.check_out' | translate }}
    </button>
  } @else {
    <button
      mat-flat-button
      class="mat-action-filled"
      [color]="recording ? 'warn' : 'primary'"
      (click)="submit()"
      [disabled]="group.invalid || (error$.value$ | async) || (workingHoursError$ | async)"
    >
      {{ (recording ? 'comego.check_out' : 'time.update') | translate }}
    </button>
  }
</mat-dialog-actions>
<ng-template #confirmDeleteDialog let-ref="dialogRef" let-data="dialogData">
  <div matDialogTitle class="mat-dialog-title-base flex flex-col">
    <h4 class="my-0">{{ 'utils.confirmDeletion' | translate }}</h4>
  </div>
  <mat-dialog-content>
    <p>{{ 'comego.confirmDelete' | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button (click)="ref.close(false)">
      {{ 'utils.cancel' | translate }}
    </button>
    <button mat-stroked-button color="warn" (click)="ref.close(true)">
      {{ 'utils.delete' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
